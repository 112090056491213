import "core-js/modules/es.array.push.js";
import { getMyExamCheckList } from "@/api/api";
export default {
  name: "examList",
  data() {
    return {
      type: null,
      //
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        name: '',
        current: 1,
        total: 0
      },
      exportIds: ''
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.type = data.type || null;
    //
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getMyExamCheckList(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    showEdit(id) {
      this.$root.useRouter.push({
        path: "/examCheck/examCheckQuesList",
        query: {
          examId: id
        }
      });
    },
    closeDiv() {
      this.dialogFormVisible = false;
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    toPage(key, item) {
      if (key === 'ocr') {
        this.$root.useRouter.push({
          path: `/examCheck/checkOCR/${item.id}`
        });
      }
      if (key === 'ai') {
        this.$root.useRouter.push({
          path: `/examCheck/checkOCR/${item.id}`
        });
      }
      if (key === 'task') {
        console.log('进入任务分配页面');
        this.$root.useRouter.push({
          path: `/examCheck/examTaskQuestList/${item.id}`
        });
      }
    }
  }
};